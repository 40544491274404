<script lang="ts">
	import { get_mobile_nav_state } from '$lib/components/context'
	let y = 0

	let is_mobile_nav_open = get_mobile_nav_state()
</script>

<svelte:window bind:scrollY={y} />

<button
	on:click={() => ($is_mobile_nav_open = !$is_mobile_nav_open)}
	type="button"
	class:scrolled={y > 5}
>
	<span class="sr-only">Hauptmenü öffnen</span>
	<!-- Heroicon name: outline/menu -->
	<svg
		class="h-8 w-auto"
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		stroke="currentColor"
		aria-hidden="true"
	>
		<path
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="2"
			d="M4 6h16M4 12h16M4 18h16"
		/>
	</svg>
</button>

<style lang="postcss">
	button {
		@apply fixed right-6 top-10 z-30 inline-flex items-center justify-center rounded p-4 text-white hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:top-12 md:top-14 lg:hidden;
	}

	.scrolled {
		@apply right-4 top-4 bg-gray-800 bg-opacity-80 transition-all duration-300 ease-in-out;
	}
</style>
