<script lang="ts">
	import { Logo, MobileNavigation } from '$lib/components'
	import { get_mobile_nav_state } from '$lib/components/context'

	export let logo: 'black' | 'white' | 'none' = 'black'
	export let bar: 'black' | 'white' | 'transparent' = 'black'
	export let home_url = '/'

	let y = 0
	let w = 0
	let previous_position = y

	let is_mobile_nav_open = get_mobile_nav_state()

	// Hide mobile menu when screen is above 1009px
	$: $is_mobile_nav_open = w > 1009 ? false : $is_mobile_nav_open

	// Ignore first 5px of scrolling
	// Hide menu when scrolling down and not at top
	$: is_menu_hidden = y > 80 ? true : false

	// Handle scrolling
	$: {
		// Scrolling upwards
		if (previous_position > y) {
			is_menu_hidden = false // show menu
			previous_position = y // reset previous_position
		} else {
			// Scrolling downwards and not at top
			if (y > 80) {
				is_menu_hidden = true
			}
			previous_position = y // reset previous_position
		}
	}
</script>

<svelte:window bind:scrollY={y} bind:innerWidth={w} />

<nav
	id="navbar"
	aria-label="Global"
	class:scrolled={y > 5}
	class:menu-hidden={is_menu_hidden}
	class={bar}
>
	<slot name="logo" color={logo}>
		<Logo color={logo} {home_url} />
	</slot>

	<slot name="menu" />
	<slot name="actions" />
</nav>
<MobileNavigation>
	<svelte:fragment slot="links">
		<slot name="mobile-links" />
	</svelte:fragment>
	<svelte:fragment slot="actions">
		<slot name="mobile-actions" />
	</svelte:fragment>
</MobileNavigation>

<style lang="postcss">
	nav :global(.btn) {
		@apply transition-all duration-300 ease-in-out;
	}

	nav {
		@apply absolute inset-x-0 top-0 z-30 grid w-full grid-cols-2 items-center justify-between p-8 transition-all duration-300 ease-in-out sm:py-10 md:px-12 md:py-12 lg:fixed lg:grid-cols-[auto_auto_auto] lg:px-16 lg:py-10;
	}

	nav.scrolled {
		@apply transition-all duration-300 ease-in-out lg:bg-opacity-100;
	}

	nav.scrolled.black {
		@apply bg-gray-900;
	}

	nav.scrolled.white {
		@apply bg-white;
	}

	nav.scrolled.transparent {
		@apply bg-transparent;
	}

	nav.menu-hidden {
		@apply -top-20 transition-all duration-300 ease-in-out;
	}

	/* Reduces size of navbar elements and padding at start of scrolling: */
	nav.scrolled:not(.mobile *) {
		@apply lg:p-4;
	}

	nav.scrolled :global(.logo) {
		@apply transition-all duration-300 ease-in-out lg:h-12;
	}

	nav.scrolled :global(.btn:not(.mobile *)) {
		@apply py-2 transition-all duration-300 ease-in-out;
	}
</style>
