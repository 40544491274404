<script lang="ts">
	import { goto } from '$app/navigation'
	import { scale } from 'svelte/transition'
	import { sineOut } from 'svelte/easing'
	import { Button, Burger, Logo } from '$lib/components'
	import { get_mobile_nav_state } from '$lib/components/context'

	export let logo: 'black' | 'white' | 'none' = 'black'

	let is_mobile_nav_open = get_mobile_nav_state()
</script>

<Burger />

{#if $is_mobile_nav_open}
	<div
		class="mobile fixed inset-x-0 top-0 z-30 max-h-screen origin-top-right transform p-2 transition"
		transition:scale|global={{ duration: 200, opacity: 0, start: 0.95, easing: sineOut }}
	>
		<div
			class="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
		>
			<div class="px-5 pb-6 pt-5 sm:pb-8">
				<div class="flex items-center justify-between">
					<Logo color={logo} />
					<div class="-mr-2 mt-2">
						<button
							on:click={() => ($is_mobile_nav_open = false)}
							type="button"
							class="inline-flex items-center justify-center rounded-md bg-white p-6 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500"
						>
							<span class="sr-only">Menü schließen</span>
							<svg
								class="h-8 w-8"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
				<div class="mt-12">
					<nav class="grid gap-6">
						<slot name="links">
							<a
								on:click={() => ($is_mobile_nav_open = false)}
								href="/"
								class="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50"
							>
								<div
									class="flex h-10 w-10 shrink-0 items-center justify-center rounded-md bg-gray-50 text-white sm:h-12 sm:w-12"
								>
									👋
								</div>
								<div class="ml-4 text-base font-medium text-gray-900">Titel</div>
							</a>
						</slot>
					</nav>
				</div>
			</div>
			<div class="flex w-full flex-col gap-4 px-5 py-6 sm:flex-row-reverse">
				<slot name="actions">
					<Button
						on:click={() => {
							$is_mobile_nav_open = false
							goto('/')
						}}
						class="w-full">CTA</Button
					>
				</slot>
			</div>
		</div>
	</div>
{/if}
